function NotFoundPage() {
  return (
    <Base>
      <Wrapper>
        <div>
          <h1>
            <LargeText>404</LargeText>
            <SmallerText>Oh no! This page doesn't exist.</SmallerText>
          </h1>
          <Text>How about this one instead?</Text>
          <RealLink href="/">Home</RealLink>
        </div>
      </Wrapper>
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Wrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.orange};
  padding: 6%;
  height: 100vh;
  text-align: center;
  letter-spacing: -0.025rem;
  color: white;
`

const LargeText = styled.span`
  font-size: 9rem;
  font-weight: 900;
  white-space: nowrap;

  ${media.sm`
    font-size: 11rem;
  `}
`

const SmallerText = styled.span`
  display: block;
  padding-top: ${p => p.theme.s5};
  font-size: ${p => p.theme.f7};
  font-weight: 800;

  ${media.sm`
    font-size: ${p => p.theme.f8};;
  `}
`

const Text = styled.p`
  ${copy}
  margin: 0 auto;
  padding-top: ${p => p.theme.s6};
  max-width: 24rem;
  font-size: ${p => p.theme.f5};
  font-weight: 700;

  ${media.sm`
    padding-top: ${p => p.theme.s7};
    font-size: ${p => p.theme.f6};
  `}
`

const RealLink = styled(Link)`
  ${underlineThatGrowsOnHover}
  padding-top: ${p => p.theme.s5};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
  color: white;

  &:after {
    background-color: ${p => p.theme.black};
  }

  ${media.sm`
    padding-top: ${p => p.theme.s6};
    font-size: ${p => p.theme.f5};
  `}
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import Base from '../ui/Base'
import { Link } from '../ui/elements'
import { copy, media, underlineThatGrowsOnHover } from '../styles'

export default NotFoundPage
